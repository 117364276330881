@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$spacing: size("gutter--large") * 3;

:export {
  small: strip-unit(breakpoint("small"));
}

@keyframes popIn {
  0% { opacity: 0; transform: scale(0.4); }
  100% { opacity: 1; transform: scale(1); }
}

.block {
  @media (min-width: breakpoint("small")) {
    display: flex;
    @include stretch();
    @include clearfix();
  }
}

.name {
  margin-top: size("gutter--small");
  margin-bottom: size("gutter");
}

.left {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size('grid-gutter'), 2);
    float: left;
    width: 50%;
    padding-right: $spacing;
  }
}

.right {
  // Product option title
  h3 {
    font-size: size("font--small");
    margin-bottom: size("gutter--mini");
  }

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size('grid-gutter'), 2);
    float: right;
    width: 50%;
  }
}

.brand {
  font-size: size("font--medium");
}

.qtyPicker, .options, .options > *  {
  @media (max-width: breakpoint("small") - 1px) {
    width: 100%;
  }
}

.qtyPicker {
  margin-bottom: size("gutter--large");

  @media (min-width: breakpoint("small")) {
    width: 135px;
  }
}

.addToCartWrapper {
  padding-top: size("gutter--large");
  padding-bottom: size("gutter--large") * 2;
}

button.addToCart {
  display: block;
  width: 100%;
}

.header {
  margin-bottom: size("gutter--large");
  padding-bottom: size("gutter");
}

.price {
  font-size: size("font--medium");
  margin: 0;
}

.splitPrice {
  margin-top: 0;
  font-size: size("font--medium");
  color: color("text--muted");
  font-style: italic;
}

.description {
  line-height: font("line-height");
  color: color("text");
  font-size: size("font--medium");

  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: size("gutter");
  }

  li + li {
    margin-top: size("gutter--small");
  }
}

.descriptionHeading {
  margin-bottom: size("gutter") !important;
  text-transform: none;
  color: color("text");
  font-size: size("font--large") !important;
}

.lists {
  @media (min-width: breakpoint("small")) {
    @include stretch();
    @include clearfix();
  }

  padding-top: size("gutter--large") * 2;
}

.relatedList {
  width: 100%;

  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size('grid-gutter'), 2);
    float: left;
    width: 50%;
  }
}

.historyList {
  @media (min-width: breakpoint("small")) {
    padding: 0 math.div(size('grid-gutter'), 2);
    float: right;
    width: 50%;
  }

  @media (max-width: breakpoint("small") - 1px) {
    margin-top: size("gutter--large");
  }
}
