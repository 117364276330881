@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  animationDuration: strip-unit(transition("duration--organisms"));
}

$itemPadding: .45rem;
$importantItemPadding: .7rem;
$bannerHeight: 80px;

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

@keyframes fadeOut {
  0% { opacity: 0.3; }
  100% { opacity: 0; }
}

@keyframes drawerIn {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

@keyframes slideDown {
  0% { opacity: .7; transform: translateY(-100px); }
  100% { opacity: 1; transform: translateY(0); }
}

.outerContainer {
  @media (min-width: breakpoint("medium")) {
    display: none;
  }
}

.container {
  z-index: 1;
  transition: transform transition("duration--organisms") transition("ease-out");
  transform: translateX(0);
  height: size("header-height");

  &.hamburgerOpen.subNavOpen {
    transform: translateX(-100%);
  }
}

.addHeight {
  height: size("header-height") + 2px;
}

.block {
  display: flex;
  z-index: 1;
}

.padding {
  padding: size("gutter") 0;
}

.logo {
  width: size("logo-width--small");
}

.wrapper {
  flex: 1 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
}

.closeText {
  font-size: size("font--small");
  margin-right: size("gutter--mini");
}

.nav {
  display: flex;
  align-items: center;

  .left & {
    margin-left: size("gutter--large") * 2;
  }
}

.login {
  margin-left: size("gutter--large") !important;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    margin-left: size("gutter--small") !important;
  }
}

.item {
  font-size: size("font--small");
  color: color("secondary");
  text-decoration: none;
  cursor: pointer;

  user-select: none;

  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  & + & {
    margin-left: size("gutter--mini");

    @media (min-width: (breakpoint("mini") - 79px)) {
      margin-left: size("gutter") * 1.2;
    }
  }

  svg + span {
    margin-left: size("gutter--mini");
  }
}


.item svg {
  color: color("secondary");
}

.item.active svg {
  color: color("secondary");
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: size("header-height");
}

.bottom {
  flex: 0 0 auto;
  color: color("third");
  font-size: size("font--medium");
  padding: size("gutter");
  padding-bottom: #{size("gutter") * 3};

  &, table {
    width: 100%;
  }

  tr:not(:last-child) td {
    padding-bottom: size("gutter--mini");
  }

  td:last-child {
    text-align: right;
  }
}

.drawer {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;

  min-height: 100vh;
  max-height: 100vh;

  background-color: #fff;

  overflow-y: auto;
  transition: transform transition("duration--organisms") transition("ease-out");

  transform: translateX(100%);
  opacity: 0;

  &Inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% + #{size("gutter")});
  }

  .hamburgerOpen & {
    transform: translateX(0%);
    opacity: 1;
  }

  .isOpening & {
    opacity: 1;
  }

  .isClosing & {
    opacity: 1;
    transform: translateX(100%) !important;
  }

  .importantItems {
    .item {
      font-weight: 400;
      padding: $importantItemPadding 0;

      &.active {
        text-decoration: underline;
      }
    }
  }

  .item {
    margin-left: 0;
    font-size: size("font--medium");
    display: block;
    padding: $itemPadding 0;

    &, svg {
      color: color("secondary");
    }
  }

  &Main {
    width: 100%;
    flex: 1 0 auto;
  }
}

.nav .item svg {
  width: 36px;
  height: 36px;
}

.categories, .brands {
  position: absolute;
  top: 0px;
  left: 100%;
  right: 0px;
  width: 100%;

  display: none;
}

.search {
  animation:  slideDown transition("duration--organisms") transition("ease-out") forwards;
}

.mode__categories .categories { display: block; }
.mode__brands .brands { display: block; }

.dim, .dimSearch {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system") - 500;
  background: #000;

  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;

  &.isClosing {
    animation: fadeOut transition("duration--organisms") transition("ease-out") forwards;
  }
}

.bannerStyling {
  top: size("header-height") + $bannerHeight !important;

  @media (max-width: breakpoint("small")) {
    top: size("header-height") * 2 !important;
  }
}

.dimSearch {
  top: size("header-height");
}
