@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";


// local variables:
// ----------------

$barWidth         : 3rem;
$barWidth--small  : 2rem;
$bodyWidth        : 20rem + $barWidth;
$bodyWidth--small : 15rem + $barWidth;
$inset            : size("gutter");
$slideDuration    : 600ms;
$barDuration      : 400ms;
$stutterDuration  : 120ms;
$dotSize        : 8px;

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(size("gutter"));
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

// ----------------


.slider {
  overflow: hidden;
}

.list {
  display: flex;
  overflow: hidden;
  transition: transform $slideDuration ease;
}

.item {
  display: flex;
  float: left;
  overflow: hidden;

  @media (max-width: breakpoint("medium") - 1px) {
    height: 320px;
    flex-direction: column;
  }

  @media (min-width: breakpoint("medium")) {
    height: 584px;
    max-height: 80vh;
  }
}

.bar {
  transition: transform $barDuration transition("swift-out");

  @media (max-width: breakpoint("medium") - 1px) {
    height: 100vh;
    width: $barWidth--small;
    position: absolute;
    bottom: 100%;
    left: 100%;
  }

  @media (min-width: breakpoint("medium")) {
    width: $barWidth;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
}

.media {
  display: flex;
  overflow: hidden;
  flex: 1 1 auto;
  transition: transform $slideDuration linear;
  background-color: #fff;

  &.isMoving {
    transform: translateX(50%);
  }

  @media (max-width: breakpoint("medium") - 1px) {
    max-height: 70vh;
  }
}

.text {
  color: white;
  transition: opacity transition("duration--long");
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: size("gutter") * 3;
  max-width: 370px;

  @media (max-width: breakpoint("medium") - 1px) {
    padding-bottom: size("gutter");
  }

  @media (max-width: breakpoint("large") - 1px) {
    font-size: size("font--small");
  }

  &.isHidden {
    opacity: 0;
  }
}

.row {
  opacity: 0;

  &:nth-child(2)   { animation-delay: $stutterDuration * 1; }
  &:nth-child(3)   { animation-delay: $stutterDuration * 2; }
  &:nth-child(4)   { animation-delay: $stutterDuration * 3; }
  &:nth-child(n+5) { animation-delay: $stutterDuration * 4; }

  .list:not(.isMoving) .text.isCurrent > & {
    animation-name: fadeIn;
    animation-duration: $slideDuration;
    animation-fill-mode: forwards;
  }
}

.image {
  width: 100%;

  @media (max-width: breakpoint("medium") - 1px) {
    display: block;
  }

  img {
    @media (max-width: breakpoint("medium") - 1px) {
      max-width: 100%;
    }
  }
}

.body {
  order: 2;
  padding: $inset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;

  @media (min-width: breakpoint("medium")) {
    flex: 0 0 auto;
    padding-top:    size("gutter--huge");
    padding-bottom: size("gutter--huge");
    padding-left:   $inset + $barWidth;
  }
}

.dots {
  overflow: hidden;
  padding: size("gutter--small");
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: breakpoint("medium") - 1px) {
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: math.div(size("gutter"),2);
  }

  @media (min-width: breakpoint("medium")) {
    padding: size("gutter");
    padding-left:   $inset + $barWidth;
  }
}

.dot {
  float: left;

  & + & {
    margin-left: size("gutter--small");

    @media (min-width: breakpoint("medium")) {
      margin-left: size("gutter");
    }
  }
}

.dotButton {
  border: none;
  border-radius: 50%;
  padding:0;
  width:  $dotSize;
  height: $dotSize;
  background-color: #9e9e9e;
  box-shadow: bottom-shadow(1);

  &.isActive {
    background-color: white;
  }

  &:active:hover {
    box-shadow: 0 0 1px color("grey", 600), inset 0 0 0 $dotSize rgba(black, .1);
  }
}

.heading {
  font-size: size("h1") - .5em;
  margin-top: 0;
}

.description {
  font-size: size("h3");
  margin-bottom: math.div(size("gutter"),2);
}

.button {
  width:100%;
}