@import "theme/ui-components/_variables.scss";

.block {
  color: color("text--muted") !important;

  svg {
    width: 25px;
    height: 25px;
  }

  &.active {
    text-decoration: underline;
    color: color("primary") !important;
    path {
      fill: color("primary") !important;
    }
  }
}

.count {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 30px;
  text-align: center;
  margin: 0 !important;

  font-size: 1.1em;
  font-weight: bold; // TODO: use correct bold weight

  @media (max-width: (breakpoint("small") - 1px)) {
    bottom: 29px;
    font-size: .8em;
  }

  &Small {
    font-size: .8em;
    bottom: 31px;
  }
}
