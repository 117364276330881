@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

@keyframes pulse {
  0% { transform: scale(1); }
  40% { transform: scale(1.03); }
  100% { transform: scale(1); }
}

.cardValue {
  display: flex;
  flex-wrap: wrap;

  @include stretch(size("gutter--mini"));

  .col {
    flex: 0 0 33.3333%;
    padding: size("gutter--mini");

    @media (max-width: breakpoint("small")) {
      flex: 0 0 50%;
    }
  }

  .button {
    width: 100%;
    font-size: size("font--large");
    padding: size("gutter") size("gutter--small");
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;

    transition: transition("duration--molecules") opacity transition("ease-in");

    &:hover:not(.active) {
      opacity: 0.7;
    }

    &.active {
      color: color("primary");
      border: 3px color("primary") solid;
      box-shadow: none;
      animation: pulse transition("duration--organisms") transition("ease-ease") forwards;
    }

    &.outOfStock {
      color: color("text--muted");
      background: color("body");
      opacity: 1 !important;
    }

    border-radius: size("border-radius");

    @media (max-width: breakpoint("small") - 1px) {
      font-size: size("font--medium");
    }
  }

  .outOfStockLabel {
    font-size: size("font--small");
    font-weight: bold;
    color: color("text--muted");
    padding: math.div(size("gutter--mini"), 2);
  }

  .shadow {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    box-shadow: -12px 12px 36px 0 rgba(141, 151, 166, 0.16);
    //border: 3px solid #fff;
    border-radius: size("border-radius");
  }

  .active .shadow {
    border-color: color("primary");
  }
}

.outOfStock .shadow {
  z-index: 1;
  box-shadow: none;
  border: 1px solid color("border--muted");
}
