@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$img-width: 180px;
$step-width: 276px;
$step-height: 52px;

.block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: size("gutter--small");
  margin-top: size("gutter--small");
  color: color("secondary");

  @media (max-width: breakpoint("tiny") - 1px) {
    margin-right: 0;
    justify-content: flex-start;
    width: 100%;
  }
}

.imgBackground {
  background-color: color("background--secondary");
  width: 270px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 120px;
  }
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $step-width;
  height: $step-height;
  font-size: size("font--small");

  @media (max-width: breakpoint("tiny") - 1px) {
    height: auto;
    justify-content: flex-start;
    padding: size("gutter--mini")
  }
}

.text {
  margin-top: 0 ;
}

.stepLink {
  text-decoration: underline;
}