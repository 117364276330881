@import "theme/ui-components/_variables.scss";

:global {
  .grecaptcha-badge { visibility: hidden; }
}

.block {
  background: color("background--secondary");
  color: color("secondary");
  padding: size("gutter");
  content-visibility: auto;
  font-size: size("font--small");

  .button {
    color: color("primary");
    
    &Language {
      margin: 0 size("grid-gutter");

      @media (max-width: breakpoint("small")) {
        margin: size("grid-gutter") 0;
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.wrapper {
  padding: size("gutter--large") 0;
}

.top {
  display: flex;
  justify-content: space-between;

  .separator {
    margin: 0 size("grid-gutter");
  }

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    gap: size("gutter--large");
  }
}

.copyright {
  border-top: 1px solid #fff;
  @media (min-width: breakpoint("mini")) {
    display: flex;
    justify-content: space-between;
    > p {
      margin-top: 0;
    }
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
}

.category {
  padding: 1rem 0;
  width: 100%;

  a {
    color: color("primary");
  }

  @media (min-width: breakpoint("mini")) {
    width: 50%;
  }

  @media (min-width: breakpoint("small")) {
    width: 33.33%;
  }

  @media (min-width: breakpoint("large")) {
    width: 25%;
  }
}
