@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$bg: color("background--secondary");
$gutter: size("gutter--mini");

.block {
  @media (min-width: breakpoint("small")) {
    border-radius: size("border-radius");
    background: $bg;
    color: color("secondary");
    display: flex;
    justify-content: space-between;
    text-decoration: none !important;

    margin-left: size("gutter--large") * 1.5;

    @media (max-width: calc(#{breakpoint("medium")} - 1px)) {
      margin-left: 0;
    }
  }
}

.left,
.right {
  @media (min-width: breakpoint("small")) {
    padding: $gutter;
  }
}

.left {
  display: flex;
  align-items: center;
  border-right: 1px solid #fff;

  @media (max-width: calc(#{breakpoint("small")} - 1px)) {
    display: none;
  }
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.balance,
.afterPurchase {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;

  @media (min-width: breakpoint("small")) {
    line-height: 1.4;
    font-size: size("font--small");
  }

  span:last-child {
    padding-left: size("gutter");
  }
}

.balance {
  @media (min-width: breakpoint("small")) {
    font-weight: bold; // TODO: correct bold?
  }
}

.afterPurchase {
  color: color("secondary");
}
