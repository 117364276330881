@use "sass:math";

@import "theme/ui-components/_variables.scss";

$bannerHeight: 30px;

.container {
  position: fixed;
  z-index: z-index("system");
  height: size("header-height");
  background: #fff;
  top: 0;
  left: 0;
  right: 0;

  &.active {
    box-shadow: -1px 3px 15px 0 rgba(141,151,166,.16);
  }
}

.bannerHeight {
  height: size("header-height") + $bannerHeight !important;

  @media (max-width: breakpoint("small")) {
    height: size("header-height") * 2 !important;
  }
}

.height {
  margin-bottom: math.div(size("header-height"), 2);
  height: size("header-height");
}
