@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/ui-components/src/ui-components/CartMini.scss";

$img-size: 47px;

.item {
  &Img{
    width: $img-size;
    height: $img-size;

  }
}

.bottom {
  a + a {
    margin-top: size("gutter--small");
  }
}
