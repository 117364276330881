@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  // negate app footer margin and apply here instead to keep background color
  padding-bottom: #{size("gutter--large") * 3};
  margin-bottom: -#{size("gutter--large") * 3};
}

.topBlock {
  @media (max-width: breakpoint("tiny") - 1px) {
    margin-top: -#{size("gutter--large")};
  }
}

.heading {
  text-align: left;
  font-size: size("font--large");
  color: color("secondary");

  @media (max-width: breakpoint("tiny")) {
    font-size: size("font--medium") !important;
  }

  &Tutorial {
    text-align: center;
    margin-top: size("gutter--section") !important;

    @media (max-width: breakpoint("tiny")) {
      text-align: left;
    }
  }
}

.headingSplit {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: size("gutter--large");
  margin-top: size("gutter--large");

  .heading {
    margin-bottom: 0;
  }
}

.hero {
  @media (min-width: breakpoint("small")) {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    margin-top: size("gutter--large");

    @media (min-width: breakpoint("small")) {
      font-size: size("font--enormous") * 1.7;
    }
  }

  p {
    font-size: size("font--medium");
    max-width: math.div(breakpoint("large"), 2.2);
  }

  button {
    margin-top: size("gutter");
  }

  img {
    max-width: 400px;
  }
}

.heroWrapper {
  background: #fff;
  padding: size("gutter--section") 0;
}

.popularBrands, .popularCategories, .popularProducts {
  text-align: center;
  padding: size("gutter") 0;

  .link {
    padding: size("gutter") 0 size("gutter-large");
  }

  .box {
    transition: transform transition("duration--atoms") transition("ease-in"),
      box-shadow transition("duration--atoms") transition("ease-in");

    &:hover {
      box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-3px);
    }
  }

  @media (max-width: breakpoint("tiny")) {
    padding: size("gutter") size("gutter--mini");
  }
}

.popularProducts {
  .cta {
    font-weight: 400;
    display: inline-block;
    flex: 0 1 auto;
    padding-right: 0;

    @media (max-width: breakpoint("small")) {
      display: none;
    }
  }
}

.popularCategories {
  @media (max-width: breakpoint("tiny")) {
    padding: 0 0 size("gutter--large");
  }

  .cta {
    font-weight: 400;
    padding-right: 0;
    @media (max-width: breakpoint("small")) {
      display: none;
    }
  }
}

.carouselWrapper {
  user-select: none;
  @media (max-width: breakpoint("tiny")) {
    overflow: hidden;
  }
}

.logos {
  display: flex;
  flex-wrap: wrap;

  img {
    height: 100px;

    @media (max-width: breakpoint("tiny")) {
      height: 50px;
    }
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 21%;
    margin: math.div(size("grid-gutter"), 2);
    min-width: 100px;
    height: 150px;
    border-radius: 3px;
    border: solid 1px color("border");

    @media (max-width: breakpoint("tiny")) {
      flex: 1 0 33%;
      height: 100px;
    }

    @media (max-width: breakpoint("tiny")) {
      height: 80px;
    }
  }
}

.desktop {
  @media (max-width: breakpoint("tiny")) {
    display: none;
  }
}

.popularBrandsButton {
  margin: size("gutter") 0 size("gutter");
  flex: 0 1 auto;
}

.double {
  display: flex;
  flex: 0 1 auto;
  margin: size("gutter") * 2 0;

  > div {
    width: 50%;
  }

  div:first-of-type {
    margin-right: size("gutter--mini");
  }

  @media (max-width: breakpoint("tiny")) {
    display: inherit;
    flex: inherit;
    margin: 0;

    > div {
      width: inherit;
    }

    div:first-of-type {
      margin-right: inherit;
    }
  }
}

.carouselItem {
  background-color: #fff;
  margin: 0 math.div(size("gutter--mini"), 2);
  cursor: pointer;
  position: relative;

  @media (min-width: breakpoint("tiny")) {
    transition: box-shadow transition("duration--atoms") transition("ease-in");

    &:hover {
      text-decoration: underline;
      color: color("text");
      box-shadow: 0 0 36px 0 rgba(141, 151, 166, 0.16);
    }
  }

  &:hover {
    &:active {
      opacity: inherit;
    }
  }

  .titleWrapper {
    margin-top: size("gutter--small");
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    width: 100%;

    @media (max-width: breakpoint("tiny")) {
      font-size: size("font--small");
      padding: 0 4px;
    }
  }

  .title {
    font-size: size("font");
    color: #fff;
    font-weight: 400;
    text-transform: none;
    margin: 0;

    @media (min-width: breakpoint("small")) {
      font-size: size("font--large");
    }
  }

  .image {
    display: block;
    margin: 0 auto;
    width: 100%;
    img {
      width:100%;
      max-width: none;
    }
  }
}

.carouselControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: size("gutter") 0;
}
