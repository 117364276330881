@import "../../node_modules/@crossroads/shop-views/src/shop-views/ProductListHero.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$banner-height: 220px;
$padding: size("gutter--large");
$padding--small: 1.2rem;

.productListHero {
  margin-bottom: size("gutter");
  max-width: breakpoint("large");
  margin-left: auto;
  margin-right: auto;

  padding-left: $padding--small;
  padding-right: $padding--small;

  @media (min-width: breakpoint("small")) {
    padding-left: $padding;
    padding-right: $padding;
  }
}

.wrapper {
  background: color("background--secondary") url("/assets/zg-kort-vit-2x.png") no-repeat center;
  background-size: $banner-height;
}

.content {
  flex-grow: 1;
  min-height: $banner-height;
  padding: size("gutter");

  @media (min-width: breakpoint("medium")) {
    padding: size("gutter--medium") size("gutter--large");
  }

  .text {
    margin: auto;
    text-align: center;
    color: color("secondary");

    @media (min-width: breakpoint("medium")) {
      max-width: 50%;
    }
  }
}