@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$banner-height: 220px;
$hero-height: 580px;
$hero-height-small: 320px;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}

:export {
  mqFour: strip-unit(breakpoint("medium"));
  mqThree: strip-unit(breakpoint("small"));
  mqTwo: strip-unit(breakpoint("tiny"));
  tiny: strip-unit(breakpoint("tiny"));
  small: strip-unit(breakpoint("small"));
}

.hero {
  display: flex;
  flex-direction: column;
  margin-bottom: size("gutter");
  overflow: hidden;

  .heroImage {
    display: flex;
    width: 100%;
    height: $hero-height;

    @media (max-width: breakpoint("small") - 1px) {
      height: $hero-height-small;
    }
  }

  img {
    width: 100%;
    object-position: center center;
    object-fit: cover;
  }

  .title {
    margin: 0 0 size("gutter");
  }

  .text {
    position: absolute;
    margin: 0;
    width: 100%;
    max-width: 570px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    text-align: center;
    @media (max-width: breakpoint("small") - 1px) {
      padding: size("gutter");
    }
  }

  .description {
    margin-bottom: 0;
  }

  .title,
  .description {
    color: #fff;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: size("gutter") 0;
  min-height: $banner-height;

  &.allGiftcards {
    background-color: color("background--secondary");
    .textWrapper {
      position: absolute;
      text-align: center;
      padding: size("gutter--small");
    }
    .description,
    .title {
      position: static;
      color: color("secondary");
    }
    .description {
      max-width: 600px;
    }
  }

  picture {
    max-width: $banner-height;
    animation: fadeIn transition("duration--templates") transition("ease-out") forwards;
  }

  .title {
    position: absolute;
    margin: 0;

    @media (max-width: breakpoint("small") - 1px) {
      font-size: size("font--huge") !important;
    }
  }
}

.description {
  text-align: center;
  color: color("text--muted");
  margin-bottom: math.div(size("gutter--section"), 2);
  line-height: font("line-height");
}
