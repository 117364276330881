@import "../../node_modules/@crossroads/shop-views/src/shop-views/CheckoutCart";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.chevronCloseIcon {
  width: 20px;
  height: 20px;

  transition: transform transition("duration--organisms") transition("ease-in");

  .open & {
    transform: rotate(180deg);
  }
}

.item {
  .image {
    border: 1px solid color("border--muted");
    border-radius: size("border-radius");

    @media (min-width: breakpoint("small")) {
      height: 100px;
      width: 100px;
    }
  }

  .right {
    flex: 1 1 40%;
  }
}