@use "sass:math";

$global-variables: (
  "font": (
    "body": "acumin-pro",
    "header": "acumin-pro",
    "fallback": sans-serif,

    "size": 87.5%, // 14 px
    "line-height": 1.6,
    "line-height--heading": 1.4,
    "line-height--breadcrumbs": 1.3em,

    "uc-letter-spacing": 0.05rem,
  ),
  "breakpoint": (
    "mini": 400px,
    "tiny": 550px,
    "tiny-small": 760px,
    "small": 802px,
    "medium": 1057px,
    "large": 1224px,
  ),
  "size": (
    "font--mini": .6rem,
    "font--small": .88rem,   // 14px
    "font": 1rem,            // 16px
    "font--medium": 1.25rem, // 20px
    "font--large": 1.5rem,
    "font--huge": 2rem,
    "font--enormous": 2.4rem,

    "grid-gutter": .5rem,
    "gutter--mini": .6rem,
    "gutter--small": .95rem,
    "gutter": 1.13rem,
    "gutter--medium": 1.5rem,
    "gutter--large": 1.78rem,
    "gutter--section": 5.9rem,
    "input-padding": 1.25rem,

    "border-radius": 4px,

    "icon-margin": .7rem,

    "header-height": 80px,
    "superuser-bar-height": 27px,

    "mobile-header-icon": 30px,
    "logo-width": 100px,
    "logo-width--small": 80px,

    "h1": 3em,
    "h2": 1.2rem,
    "h3": 1.13em,
    "h4": 1em,
    "h5": .9em,
    "h6": .7em,
  ),
  "color": (
    "text": #2b2b2b,
    "primary": #ff684d,
    "secondary": #bb967c,
    "third": #0b2035,
    "border": #cecece,
    "border--muted": #e0e4e9,
    "disabled": #eeeeee,
    "text--muted": #6d7688,
    "background": #f7f8fa,
    "background--hover": #ecf3f8,
    "background--secondary": #faf3eb,

    "positive": #1ee44d,
    "negative": #ee2020,

    "body": #ffffff,

    "OOC--color": #0b2035,

    "accent": #00646e,
  ),
  "z-index": (
    "overlay": 900,
    "system": 500,
    "popup": 100,
  ),
  "transition": (
    /*
      Microanimationer
      Atomer; Pressed state knappar, checkboxar, av/på states på knappar
    */
    "duration--atoms": 100ms,
    /*
      Mindre, mellan-avancerade animationer
      Molekyler: Dialogue-enter, dropdown, tooltip, mindre karusell
    */
    "duration--molecules": 150ms,
    /*
      Större, mellan-avancerade animationer
      Organismer; Karuseller, större dropdown, full viewport entry/exit-animationer, BlurImage fade in
    */
    "duration--organisms": 250ms,
    /*
      Avancerade animationer
      Templates; Stora page transitions, avancerade entry-animationer
    */
    "duration--templates": 500ms,

    "ease-ease": cubic-bezier(0.4, 0.0, 0.2, 1),
    "ease-out": cubic-bezier(0.0, 0.0, 0.2, 1),
    "ease-in": cubic-bezier(0.4, 0.0, 1, 1),
  ),
);

@function font($value) {
  @return map-deep-get($global-variables, "font", $value);
}

@function breakpoint($value) {
  @return map-deep-get($global-variables, "breakpoint", $value);
}

@function size($value) {
  @return map-deep-get($global-variables, "size", $value);
}

@function color($value, $saturation: null) {
  $strength: if($saturation == null, 0%, if(unitless($saturation), percentage(math.div($saturation, 1000)), $saturation));

  @if type-of($value) != color {
    $value: map-deep-get($global-variables, "color", $value);
  }

  @if type-of($value) == color {
    @return scale-color($value, $lightness: $strength);
  }

  @return null;
}

@function z-index($value) {
  @return map-deep-get($global-variables, "z-index", $value);
}

@function transition($value) {
  @return map-deep-get($global-variables, "transition", $value);
}

/// Deep set function to set a value in nested maps
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {List} $keys -  Key chaine
/// @param {*} $value - Value to assign
/// @return {Map}
@function map-deep-set($map, $keys, $value) {
  $maps: ($map,);
  $result: null;

  // If the last key is a map already
  // Warn the user we will be overriding it with $value
  @if type-of(nth($keys, -1)) == "map" {
    @warn "The last key you specified is a map; it will be overrided with `#{$value}`.";
  }

  // If $keys is a single key
  // Just merge and return
  @if length($keys) == 1 {
    @return map-merge($map, ($keys: $value));
  }

  // Loop from the first to the second to last key from $keys
  // Store the associated map to this key in the $maps list
  // If the key doesn't exist, throw an error
  @for $i from 1 through length($keys) - 1 {
    $current-key: nth($keys, $i);
    $current-map: nth($maps, -1);
    $current-get: map-get($current-map, $current-key);
    @if $current-get == null {
      @error "Key `#{$key}` doesn't exist at current level in map.";
    }
    $maps: append($maps, $current-get);
  }

  // Loop from the last map to the first one
  // Merge it with the previous one
  @for $i from length($maps) through 1 {
    $current-map: nth($maps, $i);
    $current-key: nth($keys, $i);
    $current-val: if($i == length($maps), $value, $result);
    $result: map-merge($current-map, ($current-key: $current-val));
  }

  // Return result
  @return $result;
}

/// Fetch maps within maps
/// @author Hugo Giraudel
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}


/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

$global-variables: map-deep-set($global-variables, "color" "primary--hover", color("primary", 70));
$global-variables: map-deep-set($global-variables, "color" "primary--pressed", color("primary", 70));
$global-variables: map-deep-set($global-variables, "color" "primary--loading", color("primary", 70));

@mixin OOC-BG {
  background-image: linear-gradient(23deg, #0b2035 28%, #233c54 95%),
}
