@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  background-color: #fff;
  color: color("text"); 
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: breakpoints("medium")) {
    height: 120px;
  }
}