@use "sass:math";

@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$height: 208px;
$description-rows: 4;
$description-line-height: font("line-height") - .25;
$categories-rows: 2;
$categories-line-height: font("line-height") - .25;

:export {
  mini: strip-unit(breakpoint("mini"));
  small: strip-unit(breakpoint("small"));
  medium: strip-unit(breakpoint("medium"));
  large: strip-unit(breakpoint("large"));
}

.block {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: color("text");
  overflow: hidden;
  text-decoration: none;

  transition: transform transition("duration--atoms") transition("ease-in"),
    box-shadow transition("duration--atoms") transition("ease-in");

  &:not(.dummy):hover {
    text-decoration: none;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);

    .name {
      text-decoration: underline;
    }
  }

  box-shadow: -12px 12px 36px 0 rgba(141, 151, 166, 0.16);
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: color("disabled");
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.imagePixel {
  display: block;
  flex: 0 0 auto;
  max-width: 100%;
  width: 100%;
}

.body {
  padding: size("gutter");
  text-align: left;
  // use static value to allow dummy cards
  // TODO: calculate in SASS?
  @media (min-width: breakpoint("tiny")) {
    height: $height;
  }

  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: (breakpoint("tiny") - 1px)) {
    padding: size("gutter--small");
    text-align: center;
  }

  &Bottom {
    line-height: $categories-line-height;
    height: #{$categories-rows * $categories-line-height}em;
    overflow: hidden;

    @media (max-width: (breakpoint("tiny") - 1)) {
      display: none;
    }

    &:after {
      content: "";
      display: block;
      width: 8ch;
      height: 1.6em;
      background-image: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
      pointer-events: none;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.name {
  font-weight: 400;
  color: color("secondary");

  font-size: size("font--small") !important;
  @media (min-width: breakpoint("tiny")) {
    font-size: size("font") !important;
  }

  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: normal;
  margin-bottom: size("gutter--mini") !important;
}

.description {
  p, & {
    line-height: $description-line-height;
  }

  height: #{$description-rows * $description-line-height - 0.1}em;
  overflow: hidden;

  @media (max-width: (breakpoint("tiny") - 1)) {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    width: 8ch;
    height: 1.6em;
    background-image: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.categories {
  color: color("secondary");
  margin-top: math.div(size("gutter--mini"), 2);
  margin-bottom: size("gutter--small");
  font-size: size("font--small");
  font-weight: 400;

  &:hover {
    text-decoration: underline;
    color: color("primary");
  }
  &:not(:first-child):before {
    content: ", ";
  }
}

.price {
  font-size: size("font--small");

  &Large {
    position: absolute;
    bottom: size("gutter--small");
    right: size("gutter--small");
    background: color("primary");
    padding: math.div(size("gutter--mini"), 1.4) size("gutter--mini");
    color: #fff;
    white-space: nowrap;

    @media (max-width: (breakpoint("tiny") - 1px)) {
      display: none;
    }
  }

  &Small {
    color: color("text--muted");
    @media (min-width: breakpoint("tiny")) {
      display: none;
    }
  }
}

.dummy {
  .image {
    position: relative;
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;
    background: color("disabled");
  }

  .body {
    & > * {
      flex: 1 0 auto;
    }
  }

  span, p {
    display: block;
    background: color("disabled");
  }

  .name {
    max-width: 85%;
  }

  .brand {
    max-width: 76%;
  }

  .price {
    max-width: 83%;

    position: relative;
    bottom: auto;
    right: auto;
  }

  .splitPrice {
    max-width: 92%;
  }
}
