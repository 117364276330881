@import "../../node_modules/@crossroads/shop-views/src/shop-views/DiscountCode";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

$buttonHeight: 3.4rem;

.boxHeader {
  color: color("text");
  font-weight: 600;

  padding: size("gutter") size("gutter") size("grid-gutter");

  & + main {
    border-top: none !important;
  }
}

.boxBody {
  padding: size("gutter--small") size("gutter") size("gutter");
}

.discountCode {
  align-items: center !important;
  gap: size("gutter");
  flex-direction: row !important;

  & > * {
    width: auto !important;
  }

  button {
    margin-top: 0 !important;
    height: $buttonHeight !important;
  }

  & > :first-child {
    flex: 1 1 auto !important;
    margin-right: 0 !important;
  }
}