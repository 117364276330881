@import "theme/ui-components/_variables.scss";

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 0.3; }
}

@keyframes slideDown {
  0% { opacity: .7; transform: translateY(-80px); }
  100% { opacity: 1; transform: translateY(0); }
}

$bannerHeight: 30px;

.container {
  @media (max-width: (breakpoint("medium") - 1px)) {
    display: none;
  }

  height: size("header-height");
}

.block {
  z-index: z-index("system") + 2;
  height: 100%;
  padding: 1rem 0;
  display: flex;
}

.logo {
  width: size("logo-width");
}

.wrapper {
  flex: 1 0 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.nav {
  display: flex;
  align-items: center;

  .left & {
    margin-left: auto;
    margin-right: size("gutter") * 2;
  }

  svg {
    width: 42px;
    height: 42px;
  }
}

.padding {
  padding: size("gutter") 0;
}

.login {
  margin-left: size("gutter--large");
}

.item {
  font-size: size("font--small");
  color: color("secondary");
  text-decoration: none;
  cursor: pointer;

  user-select: none;

  display: flex;
  align-items: center;

  transition: all transition("duration--organisms") transition("ease-ease");

  &:hover, &.active {
    color: color("primary");
    svg {
      stroke: color("primary");
      color: color("primary");
    }
  }

  &.active, &.active svg {
    color: color("primary") !important;
  }

  & + & {
    margin-left: size("gutter--large");
  }

  svg {
    color: color("secondary");
  }

  svg + span {
    margin-left: size("gutter--mini");
  }

  &__categories {
    svg {
      transition: all transition("duration--organisms") transition("ease-ease");
      stroke: color("secondary");

      transform: rotate(-90deg);
      width: 12px;
      height: 12px;
      top: 2px;

      margin-left: size("gutter--mini");
    }
  }

  &.cartCounter svg {
    width: 42px;
    height: 42px;
  }
}

.item__categories.active svg {
  transform: rotate(-90deg) scaleX(-1);
  stroke: color("primary");
}

.search, .categories, .brands {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  z-index: z-index("system") + 1;
  animation:  slideDown transition("duration--organisms") transition("ease-out") forwards;
}

.categories, .brands {
  background: color("secondary");
}

.dim {
  position: fixed;
  top: size("header-height");
  bottom: 0;
  left: 0;
  right: 0;
  z-index: z-index("system");
  background: #000;

  animation: fadeIn transition("duration--organisms") transition("ease-out") forwards;
}

.bannerHeight {
  top: size("header-height") + $bannerHeight;
}
