@use "sass:list";

@import "../ui-components/_variables.scss";
@import "../ui-components/_mixins.scss";

/*
  Global typography rules
*/

$block: "typography";

@mixin headings($sizes...) {
  $uniqueId: unique-id();

  %#{$uniqueId} {
    @content;
  }

  @each $size in $sizes {
    h#{$size},
    .h#{$size} {
      @extend %#{$uniqueId};
    }
  }
}



@include headings(1, 2, 3, 4, 5, 6) {
  margin: 0;
  font: 300 list.slash(1em, font("line-height--heading")) font("header"), font("fallback");
  @include vertical-margins(1.5rem);
}

h1, .h1 {
  line-height: 1;
  font-size: size("h1");

  @media (max-width: breakpoint("small")) {
    font-size: size("h1") * .75;
  }
}

h2, .h2 {
  font-size: size("h2");
  font-weight: 300;
}

h3, .h3 {
  font: 400 list.slash(1em, font("line-height")) font("body"), font("fallback");
  font-size: size("h3");
  font-weight: 300;
  color: color("text--muted");
  text-transform: uppercase;
  letter-spacing: .02em;
}

h4, .h4 {
  font-size: size("h4");
  margin-bottom: size("gutter");
  color: color("text--muted");
  text-transform: uppercase;
  font-weight: 300;
}

h5, .h5 {
  font-size: size("h5");
}

h6, .h6 {
  font-size: size("h6");
}

%anchor {
  color: color("primary");
}

p,
.paragraph {
  line-height: font("line-height");
  @include vertical-margins(size("gutter"));

  &,
  > i,
  > b,
  > em,
  > strong {
    > a {
      @extend %anchor;
    }
  }
}

.lead {
  font-size: font("size--large");
}

.anchor {
  @extend %anchor;
}

mark {
  padding: .2em;
  background: color("mark");
}

strong, b {
  font-weight: 700;
}

.#{$block} {
  line-height: font("line-height");

  ol, ul {
    line-height: font("line-height");
    padding-left: size("gutter--small");
    @include vertical-margins(size("gutter--small"));
    list-style-position: inside;

    ul {
      list-style-type: circle;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    line-height: font("line-height");

    + p {
      margin-top: 0;
    }
  }

  table {
    @include vertical-margins(size("gutter--small"));
  }

  td,
  th {
    text-align: left;
    vertical-align: baseline;
    line-height: font("line-height--heading");
  }

  tr {
    &:not(:first-child) {
      td, th {
        padding-top: .5em;
      }
    }

    &:not(:last-child) {
      td, th {
        padding-bottom: .5em;
      }
    }
  }


  td:not(:first-child) {
    padding-left: size("gutter--small");
  }

  [style*="font-size"] {
    font-size: inherit !important;
  }
}
