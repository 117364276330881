@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  text-align: left;
  @media (min-width: breakpoint("tiny")) {
    text-align: center;
  }

  p {
    color: color("text--muted");
  }
}

.card {
  margin: auto;
  margin-top: size("gutter--large");
}

.brands {
  margin-top: size("gutter--section");

  @include clearfix();
  @include stretch();

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  & > .brand {
    padding: size("grid-gutter");
    display: flex;
    align-items: center;
    justify-content: center;
    width: 138px;
    
    > a {
      width: 100%;
    }
  }
}
