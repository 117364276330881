/*
  Load pictures from upscaled and blurred to full size.
*/

@import "theme/ui-components/_variables.scss";


// local variables:
// ----------------

  $block: "bluruppicture";

  $blurStr: 80px;

// ----------------


.#{$block} {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  backface-visibility: hidden;
  user-select: none;
  transition: filter 2000ms;

  :global(.no-object-fit) & {
    display: block;
  }

  &:not(.isLoaded) {
    filter: blur($blurStr);
  }

  &__img {
    display: block;
    min-width: 100%;
    max-width: none;
    object-fit: cover;

    :global(.no-object-fit) & {
      height: 100%;
    }

    :global(.no-cssfilters) & {
      filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius="100")\9\0;
      top:  -#{$blurStr * 1.25}\9\0;
      left: -#{$blurStr * 1.25}\9\0;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        top:  auto;
        left: auto;
      }
    }
  }
}
