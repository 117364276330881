@import "../../node_modules/@crossroads/ui-components/src/ui-components/Slider.scss";
@import "theme/ui-components/_variables.scss";

$handle-color: color("primary");
$value-color: color("primary");
$handle-size: 28px;
$size: 6px;

.handle {
  background: $handle-color;
  border: none;
  &__active, &:focus {
    outline: none;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.16);
    border-color: darken($handle-color, 60);
  }
}

.value {
  background: $value-color;

  display: flex;
  align-items: center;
  justify-content: center;
}
