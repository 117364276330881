@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$row-padding: size("gutter--large") * 1.2;
$item-padding: size("gutter");
$bg: #fff;
$bg--small: color("OOC--color");

.block {
  @include OOC-BG;
  max-height: 100vh;
  overflow-y: scroll;

  @media (min-width: breakpoint("small")) {
    max-height: calc(100vh - #{size("header-height")});
    background: $bg;
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: size("header-height");
  color: color("text--muted");

  @media (max-width: breakpoint("small") - 1px) {
    color: #fff;
    padding-left: 0;
    padding-right: 0;
  }
}

.back {
  border: none;
  background: none;
  padding: 0 0 0 size("gutter--small");

  svg {
    width: size("mobile-header-icon");
    height: size("mobile-header-icon");
  }

  @media (min-width: breakpoint("small")) {
    display: none;
  }
}

.row {
  border-top: 1px solid color($bg--small, 9%);

  @media (min-width: breakpoint("small")) {
    border-top: 1px solid color($bg, 9%);
  }
  width: 100%;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: size("gutter");

  @include stretch(size("gutter--mini"));

  @media (max-width: breakpoint("small") - 1px) {
    padding: 0;
    flex-direction: column;
  }
}

.items {
  @media (min-width: breakpoint("small")) {
    &>div {
      display: flex;
      flex-wrap: wrap;
      margin: $row-padding - $item-padding;
    }
  }
}

.item {
  color: color("text");
  display: block;

  @media (max-width: breakpoint("small") - 1px) {
    color: #fff;
    font-weight: bold; // TODO: use specific font weight
    &:hover {
      text-decoration: none;
    }
  }

  @media (min-width: breakpoint("small")) {
    width: 25%;
    margin: $item-padding size("gutter--mini");
    font-size: size("font--small");
  }


  &__heading {
    @media (max-width: breakpoint("small") - 1px) {
      padding: $item-padding;
    }
  }
}

.heading {
  padding: $row-padding $row-padding $row-padding 0;
  font-size: size("font--medium");
  color: color("text");
  font-weight: bold;

  @media (max-width: breakpoint("small")) {
    padding: $item-padding;
    color: #fff;
  }
}

button.actionButton {
  @media (max-width: breakpoint("small") - 1px) {
    padding: $item-padding;
    height: 100%;
  }
}

.close {
  display: flex;
  align-items: center;

  @include resetInput;
  cursor: pointer;

  @media (max-width: breakpoint("small") - 1px) {
    svg {
      width: size("mobile-header-icon");
      height: size("mobile-header-icon");
    }
  }
}

.closeText {
  font-size: size("font--small");
  margin-right: size("gutter--mini");
}

@media (max-width: breakpoint("small") - 1px) {
  .closeText {
    display: none;
  }
}
