@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.block {
  font-size: size("font--small");
  line-height: font("line-height--breadcrumbs");
  color: color("secondary");
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.link {
  text-decoration: none;
  color: color("secondary");
}

.delimeter {
  margin: 0 8px;
  width: 8px;

  &::after {
    position: absolute;
    top: -2px;
    left: 0;
    content: "";
    border-style: solid;
    border-width: 2px 2px 0 0;
    display: inline-block;
    height: .3em;
    width: .3em;
    transform: rotate(45deg);
  }
}

.current {
  display: inline-block;
  color: color("secondary");
  opacity: 0.7;
}

