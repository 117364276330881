@import "../../node_modules/@crossroads/ui-components/src/ui-components/Input.scss";

$transparent-color: #0b2035;

.block.dark {
  .input {
    border: 1px solid color("text--muted");
    background: $transparent-color;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px $transparent-color inset;
      -webkit-text-fill-color: #fff;
    }

    .light & {
      background: #fff;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        -webkit-text-fill-color: #fff;
      }
    }

  }

  .label {
    text-transform: uppercase;
    letter-spacing: font("uc-letter-spacing");
    font-size: size("font--small");
  }

  .error .input {
    border-color: color("primary") !important;
  }

  .errors {
    color: color("primary");
    margin-top: size("gutter--mini");
  }

  .float .input:focus + .label,
  .float:not(.empty) .label {
    font-weight: 400;
    font-size: size("font--small");
  }
}
