@import "../../node_modules/@crossroads/ui-components/src/ui-components/ProductList.scss";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";

.block {
  gap: 1rem;

  &__perRow4 {
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: breakpoint("small")) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: breakpoint("medium")) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.heading {
  display: none;
}