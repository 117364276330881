@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.newsletterBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: size("grid-gutter");
  font-size: size("font--small");
  background-color: color("primary");
  color: #fff;
  line-height: 2;

  @media (max-width: breakpoint("small")) {
    flex-direction: column;
    text-align: center;
  }

  .text {
    margin: 0;
  }

  .link {
    color: #fff;
    text-decoration: underline;

    .icon {
      transform: rotate(180deg);
      height: 20px;
      width: 20px;
    }

    @media (max-width: breakpoint("small")) {
     width: 100%;
    }
  }
}