@import "theme/ui-components/_variables.scss";

$view-transition-ease: transition("ease-ease");
$view-transition-duration: transition("duration--slow");

:export {
  viewDuration: $view-transition-duration;
}

.block {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.top {
  flex: 1 0 auto;
  padding-bottom: size("gutter--large") * 3;

  .onCheckout & {
    padding-bottom: 0;
  }

  .onRedeem & {
    background: url("/assets/redeem_peach.jpg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    min-height: calc(70vh - #{size("header-height")});

    @media (max-width: (breakpoint("small") - 1px)) {
      background: none;
    }


    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
      //background: url("/assets/redeem@2x.jpg");
    }

    // larger than 1080p
    @media (min-width: 1921px) {
      //background: url("/assets/redeem@2x.jpg");
    }
  }
}

.notices {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: z-index("popup") + 1;
  box-shadow: 0 -6px 36px 0 rgba(0, 0, 0, 0.12);

  > div:not(:first-child) {
    border-top: 1px solid color("border--muted");
  }
}

.footer {
  flex: 0 0 auto;
}

.notificationBar {
  background-color: color("accent");
  color: #fff;
  border: none;
}

:global {
  .leave {
    width: 100%;
    opacity: 1;
    position: absolute;
  }

  // .leave:not(.leaving) * {
  .leave * {
    animation: none !important;
    opacity: 1 !important;
  }

  .leaving {
    opacity: 0;
    transition: $view-transition-duration opacity $view-transition-ease;
  }

  .enter {
    opacity: 0;
  }

  .entering {
    opacity: 1;
    transition: $view-transition-duration opacity $view-transition-ease;
  }
}
