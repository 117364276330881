@use "sass:math";

@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuggestedProducts";
@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";


.carouselContainer {
  // ProductCard
  a {
    margin: 3px math.div(size("gutter--small"), 2) size("gutter");
    box-shadow: none;
    border: 1px solid color("border--muted");
    border-radius: size("border-radius");

    &:hover {
      border-color: color("text--muted");
      transform: translateY(-3px);
    }
  }
}
