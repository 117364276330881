@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: color("background--secondary");
  padding: size("gutter--large");
  margin-bottom: size("grid-gutter");

  .heading {
    font-size: size("font--large");
    color: color("primary");
  }

  .form {
    display: flex;
    gap: size("gutter");

    div:first-child {
      flex: 1 1 70%;
    }

    .button {
      flex: 1 1 30%;
    }

    @media (max-width: breakpoint("small")) {
      flex-direction: column;
    }
  }
}
