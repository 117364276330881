@import "ui-components/_variables.scss";
@import "ui-components/_mixins.scss";
@import "../../node_modules/@crossroads/shop-views/src/shop-views/SuccessView.scss";

$itemPadding: 24px;
$imageSize: 80px;

.title {
  font-size: 2.25rem;

  @media (min-width: breakpoint("small")) {
    font-size: 3rem;
  }
}

.header {
  margin-bottom: size("gutter");

  .checkIconBG {
    display: none;
  }
}

.left {
  .subHeading {
    display: none;
  }
}

.item {
  border: 1px solid color("border--muted");
  box-shadow: none;
  padding: $itemPadding;

  &Container {
    align-items: center;
  }

  &Info {
    color: color("text");
    padding-right: 0;
  }

  &Price {
    color: color("text");
    font-size: size("font");
  }

  &Image {
    width: $imageSize;
    height: $imageSize;
  }
}

.steps li p {
  font-size: size("font--small");
}

.back {

  a {
    color: color("text--muted");

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.right,
.contactInfo {
  & > h2 {
    font-size: size("font--large");
  }
}