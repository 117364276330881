@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

$padding: 3rem;

.dialogue {
  padding: 0 !important;
  width: 100%;
  max-width: 400px;
}

.block {
  max-height: 100vh;
  overflow-y: auto;

  header {
    padding: #{$padding * 2} $padding $padding $padding;

    .headerContent {
      max-width: 275px;
      margin: auto
    }

    h1 {
      font-size: size("font--huge");
      color: color("text");
      text-align: center;
    }

    p {
      color: color("text");
      text-align: center;
    }

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;

      svg {
        color: color("primary");
      }
    }
  }


  .body {
    display: flex;
    flex-direction: column;
    padding-bottom: #{$padding * 2};

    a {
      display: flex;
      padding: 1rem $padding;
      justify-content: flex-start;
      align-items: center;
      border-top: 1px solid color("border--muted");
      text-decoration: none;

      span {
        color: color("primary");
      }

      &:hover {
        background-color: color("background--secondary");
        color: color("primary");
        text-decoration: none;

        span {
          color: color("primary")!important;
        }
      }

      &:last-child {
        border-bottom: 1px solid color("border--muted");
      }

      svg {
        width: 24px;
        height: 18px;
      }

      span {
        display: block;
        margin-left: 1rem;
      }
    }
  }
}
