@import "theme/ui-components/_variables.scss";
@import "theme/ui-components/_mixins.scss";

.block {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: size("gutter--large") * 2;
  padding-bottom: size("gutter--large") * 2;
}
