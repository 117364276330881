@import "../../node_modules/@crossroads/ui-components/src/ui-components/Button.scss";

.button {
  border: none;

  &.primary {
    transition: background transition("duration--atoms");
    letter-spacing: font("uc-letter-spacing");
    font-size: size("font");
    font-weight: 300;
    box-shadow: none;

    &:enabled:hover {
      background: color("primary--hover");
    }
  }

  &.secondary {
    @include commonButton(
      $background: color("secondary"),
      $color: #fff,
      $br: size("border-radius"),
    );

    transition: background transition("duration--atoms");
    letter-spacing: font("uc-letter-spacing");
    font-size: size("font");
    font-weight: 300;
  }

  &.secondarydark {
    @include commonButton(
      $background: #486fe3,
      $color: #fff,
      $br: size("border-radius"),
    );

    transition: background transition("duration--atoms");
    letter-spacing: font("uc-letter-spacing");
    font-size: size("font");
    font-weight: 300;
  }

  &.blank {
    padding: size("gutter--mini") * 1.1 size("gutter--large");
    font-weight: 300;
    font-size: size("h5");
    color: color("primary");
    letter-spacing: font("uc-letter-spacing");

    &:enabled:hover {
      color: color("primary", 30%);
      background-color: transparent;
      text-decoration: underline;
    }

    &:enabled:active:hover {
      color: color("primary", 30%);
      background-color: transparent;
      text-decoration: underline;
    }
  }

  &.negative {
    @include commonButton(
      $background: #fff,
      $color: color("primary"),
      $br: size("border-radius"),
    );

    transition: background transition("duration--atoms");
    font-weight: 300;
    letter-spacing: font("uc-letter-spacing");

    &:enabled {
      &:hover {
        background: color("background--hover");
        text-decoration: none;
      }
    }
  }

  &.ghost {
    @include commonButton(
      $color: color("primary"),
      $background: inherit,
      $borderColor: color("primary"),
      $br: size("border-radius"),
    );

    transition: all transition("duration--atoms");
    border-width: 2px;
    font-size: size("font");
    font-weight: 300;


    &:enabled {
      &:hover {
        border-color: lighten(color("primary"), 10);
        color: lighten(color("primary"), 10);
      }

      &:active:hover {
        border-color: lighten(color("primary"), 20);
        color: lighten(color("primary"), 20);
      }

      html[data-whatintent="keyboard"] &:focus {
        border-color: lighten(color("primary"), 10);
        color: lighten(color("primary"), 10);
      }
    }
  }

}

.hasVariant.size {
  &__large {
    padding: size("gutter--mini") * 1.17 size("gutter--large") size("gutter--mini") * 1.1;
  }

  &__medium {
    padding: size("gutter--mini") * 1.6 size("gutter") size("gutter--mini") * 1.6;
    padding-bottom: size("gutter--mini") * 1.88; // Make up for uneven font
  }

  &__small {
    padding: size("gutter--mini") * 1.17 size("gutter--small") size("gutter--mini") * 1.1;
  }
}

